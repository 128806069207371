import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Intro from 'ui-kit/intro';
import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';

import Button, { ButtonGroup } from 'ui-kit/button';
import { P, Headline, Highlight } from 'ui-kit/typography';

import Layout from '../../components/layout';
import TeaserSection from '../../components/teaser-section';

const DownloadsPage = ({ data }) => {
    /** Bilder */
    const stageImage = data.stageImage.childImageSharp;
    const downloads = data.allDownloadsJson.edges.map(({ node }) => node);

    return (
        <Layout description="Downloads">
            <Stage
                image={stageImage}
                imageAlt="Kinderhand hält einen Strauß Wildblumen"
                tag="Service & Kontakt | Downloads"
                headline={{ text: 'Wir helfen Ihnen gerne weiter', level: 'h1' }}
                bottomShade
            />
            <Section>
                <Intro title="Jetzt mehr erfahren – in unseren Broschüren">
                    Sie suchen weitere Informationen über die Südbayerische Fleischwaren GmbH oder
                    über unsere Produkte? Hier werden Sie fündig. Laden Sie sich einfach das PDF der
                    gewünschten Broschüre herunter oder blättern Sie gleich online.
                </Intro>
            </Section>
            <Section container="s" bg="gray" textAlign="center">
                {downloads.map((download, index) => {
                    const { pdf, flippingBook } = download;
                    return (
                        <React.Fragment key={download.title}>
                            <Headline level="h3">{download.title}</Headline>
                            <P gap="l">
                                <Highlight>Größe:</Highlight> {(pdf.size / 1024 / 1024).toFixed(2)}{' '}
                                MB
                            </P>
                            <ButtonGroup justify="center">
                                <Button linkTo={pdf.publicURL}>Broschüre herunterladen</Button>
                                <Button linkTo={flippingBook}>Broschüre zum Blättern</Button>
                            </ButtonGroup>
                            {index !== downloads.length - 1 && <hr />}
                        </React.Fragment>
                    );
                })}
            </Section>
            <TeaserSection />
        </Layout>
    );
};

DownloadsPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        allDownloadsJson: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(
            relativePath: { eq: "service-and-contact-pages/buehne-service-und-kontakt.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        allDownloadsJson {
            edges {
                node {
                    title
                    pdf {
                        publicURL
                        size
                    }
                    flippingBook
                }
            }
        }
    }
`;

export default DownloadsPage;
